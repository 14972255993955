import React, { type ReactElement, useCallback } from "react";
import { type ProductSchema } from "../../../interfaces/Response";
import { Badge } from "../../Badge";
import clsx from "clsx";
import { Input } from "./Input";
import { LockClosedIcon } from "@heroicons/react/24/outline";

interface TreeDataProps {
  values: ProductSchema | undefined;
  depth?: number;
  onChange: (id: string, value: string) => void;
}

export function TreeData({
  values,
  depth = 0,
  onChange,
}: TreeDataProps): ReactElement {
  const SubSchema = useCallback(({ values, depth = 0 }: TreeDataProps) => {
    return (
      <div className={"w-full"}>
        {depth !== 0 && (
          <dl className={"border-b-gray-200 border-b"}>
            <div
              className={
                "px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-4 flex items-center"
              }
            >
              <dt
                className={clsx(
                  `pl-${(depth - 1) * 4}`,
                  "text-sm font-medium leading-6 text-gray-900 flex gap-4 items-center justify-between ",
                )}
              >
                {values?.schemaKey}
                {values?.schemaLocked === true && (
                  <LockClosedIcon className={"w-4 h-4 text-violet-600"} />
                )}
              </dt>
              <dd
                className={
                  "mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0"
                }
              >
                {values?.schemaType !== undefined && (
                  <div className={"flex justify-between gap-6"}>
                    <div className={"w-full"}>
                      <Input
                        id={values.id}
                        name={values.schemaKey}
                        type={values?.schemaType}
                        value={values?.schemaValue}
                        locked={
                          values?.schemaLocked !== undefined &&
                          values.schemaLocked
                        }
                        onChange={onChange}
                      />
                    </div>
                    <Badge label={values?.schemaType} />
                  </div>
                )}
              </dd>
            </div>
          </dl>
        )}
        {values?.["product-schemas"]?.map((ps) => {
          return (
            <SubSchema
              values={ps}
              key={ps.id}
              depth={depth + 1}
              onChange={onChange}
            />
          );
        })}
      </div>
    );
  }, []);
  return (
    <div className="overflow-scroll ring-1 ring-gray-500 ring-opacity-25 rounded bg-white">
      <SubSchema values={values} depth={depth} onChange={onChange} />
    </div>
  );
}
