import React, { type ReactElement } from "react";
import { QueryClient } from "@tanstack/react-query";
import { getUserLicenses } from "../../../services/getUserLicenses";
import { useGetUserLicenses } from "../../../hooks/useGetUserLicenses";
import { useAxios } from "../../../hooks/useAxios";

export async function loader(): Promise<any> {
  const axiosInstance = useAxios();
  const queryClient = new QueryClient();
  return await queryClient.fetchQuery({
    queryKey: [axiosInstance, "user-licenses"],
    queryFn: async () => await getUserLicenses(axiosInstance),
  });
}

export function UserDashboard(): ReactElement {
  // /**
  //  * Data Filters
  //  */
  // const [filterOptions, setFilterOptions] = useState<FilterOption[]>([]);

  // const onFilter = useCallback((filters: FilterOption[] | undefined) => {
  //   const options = Filters(FilterResponse.License);
  //   filters?.forEach((filter, index) => {
  //     const option = options.find((f) => f.field === filter.field);
  //     if (option !== undefined) {
  //       option.value = filter.value;
  //     }
  //   });
  //   setFilterOptions(
  //     options.filter((f) => f.value !== undefined && f.value.trim().length > 0),
  //   );
  // }, []);

  /**
   * Data Retrieval
   */
  const axiosInstance = useAxios();
  const { data } = useGetUserLicenses(axiosInstance, { filterOptions: [] });

  return (
    <>
      <h1>User Dashboard</h1>
      <span>{data != null ? data.message : "no data"}</span>
    </>
  );
}
