import React, { useCallback, useMemo, useState } from "react";
import { Heading } from "../../../components/Heading";
import { DataGrid } from "../../../components/Tabular/";
import { CopyToClipboard } from "../../../components/CopyToClipboard";
import { ModelLink } from "../../../components/ModelLink";
import { ButtonLink } from "../../../components/ButtonLink";
import { Container } from "../../../components/Container";
import { QueryClient } from "@tanstack/react-query";
import { getAdminLicenses } from "../../../services/getAdminLicenses";
import { useGetLicenses } from "../../../hooks/useGetLicenses";
import { type FilterOption } from "../../../interfaces/FilterOption";
import { Filters } from "../../../utils/Filters";
import { FilterResponse } from "../../../interfaces/FilterResponse";
import { TableContainer } from "../../../components/TableContainer";
import { useAxios } from "../../../hooks/useAxios";
import { Badge } from "../../../components/Badge";

export async function loader(): Promise<any> {
  const axiosInstance = useAxios();
  const queryClient = new QueryClient();
  return await queryClient.fetchQuery({
    queryKey: [axiosInstance, "licenses"],
    queryFn: async () => await getAdminLicenses(axiosInstance),
  });
}

export function LicensesRoute(): React.ReactElement {
  /**
   * Data Filters
   */
  const [filterOptions, setFilterOptions] = useState<FilterOption[]>([]);

  const onFilter = useCallback((filters: FilterOption[] | undefined) => {
    const options = Filters(FilterResponse.License);
    filters?.forEach((filter, index) => {
      const option = options.find((f) => f.field === filter.field);
      if (option !== undefined) {
        option.value = filter.value;
      }
    });
    setFilterOptions(
      options.filter((f) => f.value !== undefined && f.value.trim().length > 0),
    );
  }, []);

  /**
   * Data Retrieval
   */
  const axiosInstance = useAxios();
  const { data: licenses } = useGetLicenses(axiosInstance, { filterOptions });

  const cols = [
    {
      key: "issuer",
      label: "Issuer",
    },
    {
      key: "product",
      label: "Product",
      span: 2,
    },
    {
      key: "user",
      label: "User",
    },
    {
      key: "actions",
      label: "ID",
      span: 2,
    },
  ];

  const rows = useMemo(() => {
    return licenses?.map((license) => ({
      issuer: {
        label: license.issuers.name,
      },
      product: [
        {
          label: license.products.name,
        },
        {
          children: (
            <Badge
              label={`${license["product-keys"].version_major ?? "0"}.${license["product-keys"].version_minor ?? "0"}.${license["product-keys"].version_revision ?? "0"}`}
            />
          ),
        },
      ],
      user: {
        children: (
          <ModelLink
            key={license.users.id}
            to={`/admin/users/${license.users.id}`}
          >
            {license.users.name}
          </ModelLink>
        ),
      },
      actions: [
        {
          children: <CopyToClipboard text={license.id} />,
        },
        {
          children: (
            <ButtonLink to={`/admin/licenses/${license.id}`}>
              Details
            </ButtonLink>
          ),
        },
      ],
    }));
  }, [licenses]);

  return (
    <>
      <Heading
        title={"Licenses"}
        rightAccessory={
          <button
            type="button"
            className="block rounded-md bg-violet-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-violet-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-violet-600"
          >
            Add License
          </button>
        }
        filters={Filters(FilterResponse.License)}
        onFilter={onFilter}
      />
      <Container className={"pt-12"}>
        <TableContainer>
          <DataGrid cols={cols} rows={rows} />
        </TableContainer>
      </Container>
    </>
  );
}
