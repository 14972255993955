import {
  type DefaultError,
  useQuery,
  type UseQueryResult,
} from "@tanstack/react-query";
import { type FilterOption } from "../interfaces/FilterOption";
import { getUserLicenses } from "../services/getUserLicenses";
import type { AxiosInstance } from "axios";

export const useGetUserLicenses = (
  axiosInstance: AxiosInstance,
  {
    filterOptions,
  }: {
    filterOptions: FilterOption[];
  },
): UseQueryResult<{ message: string }, DefaultError> => {
  return useQuery({
    queryKey: [axiosInstance, "user-licenses", filterOptions],
    queryFn: async () => await getUserLicenses(axiosInstance, filterOptions),
  });
};
