import { API_URL } from "../constants/Servers";
import type { AxiosInstance } from "axios";

export const patchAdminLicenseStateValues = async (
  axiosInstance: AxiosInstance,
  {
    licenseStateID,
    data,
  }: {
    licenseStateID?: string;
    data: {
      schemaKey: string;
      schemaValue: string;
    };
  },
): Promise<any> => {
  return await axiosInstance
    .patch(`${API_URL}/admin/license-state-values/${licenseStateID}`, data)
    .then((res) => {
      return res;
    });
};
