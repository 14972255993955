import React, {
  type ChangeEvent,
  type ReactElement,
  useCallback,
  useEffect,
  useState,
} from "react";
import { CopyToClipboard } from "../../../CopyToClipboard";

export function StringInput({
  id,
  name,
  value,
  locked,
  onChange,
}: {
  id: string;
  name: string;
  value?: string;
  locked: boolean;
  onChange: (id: string, value: string) => void;
}): ReactElement {
  const [firstRender, setFirstRender] = useState<boolean>(true);
  const [inputValue, setInputValue] = useState<string>(value ?? "");
  const [debouncedInputValue, setDebouncedInputValue] = React.useState("");
  const _onChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);
  }, []);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setDebouncedInputValue(inputValue);
    }, 500);
    return () => {
      clearTimeout(timeout);
    };
  }, [inputValue]);

  useEffect(() => {
    if (firstRender) {
      setFirstRender(false);
    } else {
      !locked && onChange(id, debouncedInputValue);
    }
  }, [debouncedInputValue]);

  return locked ? (
    <CopyToClipboard text={value} />
  ) : (
    <div className={"w-full"}>
      <label htmlFor={name} className="sr-only">
        {name}
      </label>
      <input
        disabled={locked}
        type="text"
        name={name}
        id={name}
        className="block w-full rounded-md border-0 py-1.5 px-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-violet-600 sm:text-sm sm:leading-6 font-mono"
        value={inputValue}
        onChange={_onChange}
      />
    </div>
  );
}
