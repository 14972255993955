import React, { type ReactElement } from "react";
import { BooleanInput } from "./BooleanInput";
import { NumberInput } from "./NumberInput";
import { StringInput } from "./StringInput";

export function Input({
  id,
  name,
  type,
  value,
  locked,
  onChange,
}: {
  id: string;
  name?: string;
  type: string;
  value?: string;
  locked: boolean;
  onChange: (id: string, value: string) => void;
}): ReactElement {
  switch (type) {
    case "boolean":
      return (
        <BooleanInput
          id={id}
          name={name as unknown as string}
          value={Boolean(value) && value === "true"}
          locked={locked}
          onChange={onChange}
        />
      );
    case "number":
      return (
        <NumberInput
          id={id}
          name={name as unknown as string}
          value={Number(value ?? 0)}
          locked={locked}
          onChange={onChange}
        />
      );
    case "string":
      return (
        <StringInput
          id={id}
          name={name as unknown as string}
          value={value as unknown as string}
          locked={locked}
          onChange={onChange}
        />
      );
    default:
      return <></>;
  }
}
