import React, {
  type ChangeEvent,
  Fragment,
  type ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Label, Listbox, Transition } from "@headlessui/react";
import {
  CheckIcon,
  ChevronDownIcon,
  ChevronUpDownIcon,
  ChevronUpIcon,
} from "@heroicons/react/24/outline";
import clsx from "clsx";

interface NumberInputOption {
  id: string;
  name: string;
  value?: number;
}
export function NumberInput({
  id,
  name,
  value,
  locked,
  onChange,
}: {
  id: string;
  name: string;
  value: number;
  locked: boolean;
  onChange: (id: string, value: string) => void;
}): ReactElement {
  const options: NumberInputOption[] = useMemo(() => {
    return [
      {
        id: "unlimited",
        name: "Unlimited",
        value: -1,
      },
      {
        id: "zero",
        name: "Zero",
        value: 0,
      },
      {
        id: "non-zero",
        name: "Non-Zero",
      },
    ];
  }, []);
  const [firstRender, setFirstRender] = useState<boolean>(true);
  const valueToOption = useMemo((): NumberInputOption => {
    if (value <= 0) {
      return options.filter((o) => o.value === value)[0];
    }
    return options.filter((o) => o.value === undefined)[0];
  }, [value]);
  const [selected, setSelected] = useState(valueToOption);
  const [count, setCount] = useState<number>(value);
  const [debouncedCount, setDebouncedCount] = useState<number>(count);
  const incrementCount = useCallback(() => {
    if (locked) {
      return;
    }
    setCount((prev) => {
      return prev + 1;
    });
  }, [locked]);
  const decrementCount = useCallback(() => {
    if (locked) {
      return;
    }
    setCount((prev) => {
      const newCount = prev - 1;
      return newCount >= 1 ? newCount : 1;
    });
  }, [locked]);

  const onBlur = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const input = Number(event.currentTarget.value);
      setCount(input > 0 ? input : count);
    },
    [count],
  );

  useEffect(() => {
    if (!firstRender) {
      if (selected.value !== undefined) {
        setCount(selected.value);
      } else {
        setCount(1);
      }
    }
  }, [selected]);

  useEffect(() => {
    const interval = setInterval(() => {
      setDebouncedCount(count);
    }, 500);
    return () => {
      clearInterval(interval);
    };
  }, [count]);

  useEffect(() => {
    if (firstRender) {
      setFirstRender(false);
    } else {
      !locked && onChange(id, String(debouncedCount));
    }
  }, [debouncedCount]);

  const Counter = useCallback(() => {
    return (
      <span className="isolate inline-flex rounded-md shadow-sm">
        <button
          type="button"
          className="relative inline-flex items-center rounded-l-md bg-white px-2 py-1.5 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10"
          onClick={decrementCount}
        >
          <span className="sr-only">Subtract</span>
          <ChevronDownIcon className="h-4 w-4" aria-hidden="true" />
        </button>
        <div className="relative inline-flex items-center justify-center border-t border-b border-gray-300 bg-white px-2 h-9 text-gray-600">
          <input
            className={"font-mono w-10"}
            placeholder={count as unknown as string}
            onBlur={onBlur}
          />
        </div>
        <button
          type="button"
          className="relative -ml-px inline-flex items-center rounded-r-md bg-white px-2 py-1.5 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10"
          onClick={incrementCount}
        >
          <span className="sr-only">Add</span>
          <ChevronUpIcon className="h-4 w-4" aria-hidden="true" />
        </button>
      </span>
    );
  }, [incrementCount, decrementCount, count]);
  return (
    <div className={"flex gap-4"}>
      <Listbox disabled={locked} value={selected} onChange={setSelected}>
        {({ open }) => (
          <>
            <Label className="sr-only">{name}</Label>
            <div className="relative w-40">
              <Listbox.Button className="relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-violet-600 sm:text-sm sm:leading-6">
                <span className="block truncate">{selected.name}</span>
                <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                  <ChevronUpDownIcon
                    className="h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                </span>
              </Listbox.Button>
              <Transition
                show={open}
                as={Fragment}
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                  {options.map((option) => (
                    <Listbox.Option
                      key={option.id}
                      className={({ focus }) =>
                        clsx(
                          focus ? "bg-violet-600 text-white" : "text-gray-900",
                          "relative cursor-default select-none py-2 pl-3 pr-9",
                        )
                      }
                      value={option}
                    >
                      {({ selected, focus }) => (
                        <>
                          <span
                            className={clsx(
                              selected ? "font-semibold" : "font-normal",
                              "block truncate",
                            )}
                          >
                            {option.name}
                          </span>
                          {selected ? (
                            <span
                              className={clsx(
                                focus ? "text-white" : "text-violet-600",
                                "absolute inset-y-0 right-0 flex items-center pr-4",
                              )}
                            >
                              <CheckIcon
                                className="h-5 w-5"
                                aria-hidden="true"
                              />
                            </span>
                          ) : null}
                        </>
                      )}
                    </Listbox.Option>
                  ))}
                </Listbox.Options>
              </Transition>
            </div>
          </>
        )}
      </Listbox>
      {selected.value === undefined && <Counter />}
    </div>
  );
}
