import {
  type DefaultError,
  useQuery,
  type UseQueryResult,
} from "@tanstack/react-query";
import { type ProductSchema } from "../interfaces/Response";
import type { AxiosInstance } from "axios";
import { getAdminLicenseStateValues } from "../services/getAdminLicenseStateValues";

export const useGetAdminLicenseStateValues = (
  axiosInstance: AxiosInstance,
  {
    licenseStateID,
  }: {
    licenseStateID?: string;
  },
): UseQueryResult<ProductSchema, DefaultError> => {
  return useQuery({
    queryKey: [axiosInstance, "license-state-values", licenseStateID],
    queryFn: async () =>
      await getAdminLicenseStateValues(axiosInstance, { licenseStateID }),
    enabled: licenseStateID !== undefined,
  });
};
