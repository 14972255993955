import { type Params, useParams } from "react-router-dom";
import { QueryClient } from "@tanstack/react-query";
import { getAdminLicense } from "../../../services/getAdminLicense";
import React, { type ReactElement, useCallback } from "react";
import { useAxios } from "../../../hooks/useAxios";
import { useGetLicense } from "../../../hooks/useGetLicense";
import { Heading } from "../../../components/Heading";
import { Container } from "../../../components/Container";
import { CopyToClipboard } from "../../../components/CopyToClipboard";
import { DefinitionList } from "../../../components/Tabular";
import { useGetAdminLicenseStateValues } from "../../../hooks/useGetAdminLicenseStateValues";
import { TreeData } from "../../../components/Schema";
import { usePatchAdminLicenseStateValue } from "../../../hooks/usePatchAdminLicenseStateValue";

export async function loader({
  request,
  params,
}: {
  request: Request;
  params: Params;
}): Promise<any> {
  const axiosInstance = useAxios();
  const queryClient = new QueryClient();
  return await queryClient.fetchQuery({
    queryKey: [axiosInstance, "license", params.licenseID],
    queryFn: async () =>
      await getAdminLicense(axiosInstance, {
        licenseID: `${params.licenseID}`,
      }),
  });
}

export function LicenseRoute(): ReactElement {
  const { licenseID } = useParams();

  /**
   * Data Retrieval
   */
  const axiosInstance = useAxios();
  const { data: license } = useGetLicense(axiosInstance, {
    licenseID: `${licenseID}`,
  });

  const { data: licenseSchema } = useGetAdminLicenseStateValues(axiosInstance, {
    licenseStateID: license?.["license-states"].id,
  });

  /**
   * Data Mutations
   */
  const mutation = usePatchAdminLicenseStateValue(axiosInstance);

  const onSchemaChange = useCallback(
    (id: string, value: string) => {
      if (license?.["license-states"].id !== undefined) {
        mutation.mutate({
          schemaState: license?.["license-states"].id,
          schemaKey: id,
          schemaValue: value,
        });
      }
    },
    [license?.["license-states"].id],
  );

  if (license === undefined) {
    return <></>;
  }

  return (
    <>
      <Heading
        title={"Manage License"}
        rightAccessory={
          <button
            type="button"
            className="block rounded-md bg-violet-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-violet-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-violet-600"
          >
            Edit License
          </button>
        }
      />
      <Container className={"pt-12"}>
        <DefinitionList
          cols={[
            {
              key: "id",
              label: "ID",
            },
            {
              key: "issuer",
              label: "Issuer",
            },
            {
              key: "product",
              label: "Product",
            },
            {
              key: "productKeyVersion",
              label: "Version",
            },
            {
              key: "user",
              label: "User",
            },
          ]}
          rows={[
            {
              id: {
                children: <CopyToClipboard text={license.id} />,
              },
            },
            {
              issuer: {
                label: license.issuers.name,
              },
            },
            {
              product: {
                label: license.products.name,
              },
            },
            {
              productKeyVersion: {
                label: `${license["product-keys"].version_major ?? 0}.${license["product-keys"].version_minor ?? 0}.${license["product-keys"].version_revision ?? 0}`,
              },
            },
            {
              user: {
                label: license.users.name,
              },
            },
            // {
            //   date: {
            //     label: new Date(
            //       (license["license-states"]
            //         .date_created as unknown as number) * 1000,
            //     ).toDateString(),
            //   },
            // },
            // {
            //   state: {
            //     children: (
            //       <CopyToClipboard text={license["license-states"].id} />
            //     ),
            //   },
            // },
          ]}
        />
      </Container>
      <Container className={"pt-12 pb-12"}>
        <TreeData onChange={onSchemaChange} values={licenseSchema} />
      </Container>
    </>
  );
}
