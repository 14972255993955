import { useMutation, type UseMutationResult } from "@tanstack/react-query";
import { type AxiosInstance } from "axios";
import { patchAdminLicenseStateValues } from "../services/patchAdminLicenseStateValue";
import type { DefaultError } from "@tanstack/query-core";

interface PatchAdminLicenseState {
  schemaState: string;
  schemaKey: string;
  schemaValue: string;
}
export const usePatchAdminLicenseStateValue = (
  axiosInstance: AxiosInstance,
): UseMutationResult<void, DefaultError, PatchAdminLicenseState> => {
  return useMutation({
    mutationFn: async ({
      schemaState,
      schemaKey,
      schemaValue,
    }: PatchAdminLicenseState) => {
      const data = { schemaKey, schemaValue };
      return await patchAdminLicenseStateValues(axiosInstance, {
        licenseStateID: schemaState,
        data,
      });
    },
  });
};
