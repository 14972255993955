import { type Licenses } from "../interfaces/Response";
import type { FilterOption } from "../interfaces/FilterOption";
import { API_URL } from "../constants/Servers";
import type { AxiosInstance } from "axios";

export const getUserLicenses = async (
  axiosInstance: AxiosInstance,
  filterOptions?: FilterOption[],
): Promise<Licenses[]> => {
  const params: { filter: Record<string, string> } = {
    filter: {},
  };
  filterOptions?.forEach((f) => {
    if (f.value != null) {
      params.filter[f.field] = f.value;
    }
  });
  return await axiosInstance
    .get(`${API_URL}/user/licenses`, {
      params,
    })
    .then((res) => {
      return res.data;
    });
};
